* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f0f0f0;
    height: 100%;
}

.main-page {
    flex-grow: 1;
    display: flex;
    justify-content: space-evenly;
    padding: 12px;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
}

.graph-container {
    flex-grow: 8;
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 400px;
}

@font-face {
    font-family: '3d';
    src: url('../font/3d-isometric-font/3DIsometric-YzLLy.ttf') format('truetype');
}

.header {
    background-color: #fff;
    color: #000;
    padding: 20px;
    text-align: center;
}

.header h1 {
    font-family: '3d', sans-serif;
    font-size: 60px;
    letter-spacing: 2px;
    margin-top: 0;
}

.text-input {
    flex-grow: 1;
    resize: none;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 16px;
    height: 100%;
    overflow: auto;
    width: 10%;
}

circle:hover {
    cursor: pointer;
}

@media screen and (max-width: 1103px) {
    .main-page > *{
        height: 500px;
    }
}

@media screen and (max-width: 700px) {
    .main-page > * {
        height: auto;
    }

    .main-page {
        row-gap: 12px;
        padding: 12px;
        width: 100%;
        height: auto;
    }

    .graph-container{
        height: 400px;
    }

    .text-input {
        width: 100%;
    }
}