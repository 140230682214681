.controls {
    width: 23%;
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: auto;
    flex-direction: column;
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}

.tab {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab:hover {
    background-color: #f0f0f0;
}

.tab.active {
    font-weight: bold;
    border-bottom: 2px solid #000;
}

.controls-content {
    flex-grow: 1;
    padding: 20px;
    text-align: center;
}

hr {
    margin-top: 50px;
}

.interactive {
    padding: 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
}

.interactiveNoBorders {
    display: flex;
    align-items: center;
}

.label-text {
    margin-right: 10px;
}

.switch-container {
    display: flex;
    align-items: center;
}

.switch {
    margin-left: auto;
    display: inline-block;
    position: relative;
    width: 34px;
    height: 20px;
    cursor: pointer;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:checked+.slider:before {
    transform: translateX(14px);
}

.button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: transparent;
    border: 1px solid;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.button:hover {
    background-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
}

.control-heading {
    margin-top: -10px;
    margin-bottom: 30px;
}

.universal-controls {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    background-color: rgba(255, 255, 255, 1);
    color: black;
    padding: 8px 16px;
    font-size: 14px;
    border: 1px solid;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.dropdown-menu {
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 5px;
    text-align: left;
}

.dropdown-menu .button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropdown-menu .button:hover {
    background-color: #ddd;
}

.control-item {
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;
}

.control-item label {
    margin-right: 10px;
    width: 80px;
    text-align: center;
}

.control-item input {
    max-width: 160px;
}

.note-box {
    margin: 20px;
    border: 1px solid #ffd700;
    border-radius: 5px;
    background-color: #ffffe0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #000;
    padding: 10px;
}

.note-box p {
    font-size: 17px;
    text-align: center;
}

div h3{
    margin-top: 20px;
}

@media (max-width: 700px) {
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .row2 {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1103px) {
    .controls {
        width: 100%;
    }
}

.node-size-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Adds spacing between label, slider, and text */
    margin-top: 15px;
}

.node-size-container input {
    width: 60%;
    max-width: 200px;
}

.node-size-container span {
    font-size: 14px;
    font-weight: bold;
}
